  <template>
    <div class="withdraw page">
      <headers title="提现"></headers>
      <div class="scroll">
        <ul class="bank_info">
          <li>
            <div class="label">到账银行</div>
            <div class="value">{{ info ? info.bankname : '中国' }}</div>
          </li>
        </ul>
        <div class="form">
          <div class="item" v-for="(item, index) in form_list" :key="index">
            <img :src="item.icon" class="icon" alt="">
            <div>
              <van-field v-model="item.value" name="" label="" :placeholder="item.placeholder" :type="item.type"
                :rules="[{ required: true, message: item.placeholder }]" />
            </div>
          </div>
        </div>
      </div>
      <div class="btnBox">
        <van-button class="btn" round block type="info" native-type="submit"
          color="linear-gradient(180deg,#9eb0ff,#4969ff)" @click="submit()">提交</van-button>
      </div>
    </div>
  </template>

<script>
import headers from "@/components/headers.vue"
import { Toast } from "vant";
export default {
  name: "withdraw",
  components: {
    headers
  },
  data() {
    return {
      info: {},
      form_list: [
        {
          required: 0,
          icon: require('/static/img/login_name.png'),
          type: 'text',
          key: 'username',
          placeholder: '请输入提现姓名',
          value: ''
        },
        {
          required: 0,// required 为1 必填项
          icon: require('/static/img/account.png'),
          key: 'remark',
          type: 'text',
          placeholder: '请输入提现账户',
          value: ''
        },

        {
          required: 1,    // required 为1 必填项
          icon: require('/static/img/money2.png'),
          type: 'number',
          key: 'money',
          placeholder: '请确认提现金额',
          toastMsg: '提现金额不能为空',
          value: ''
        },
        {
          required: 1,    // required 为1 必填项
          icon: require('/static/img/login_pw.png'),
          key: 'password',
          type: 'password',
          placeholder: '请确认提现密码',
          toastMsg: '提现密码不能为空',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      $api.bankInfo({
        token: this.$store.getters['getToken']
      }).then(res => {
        this.info = res.data;
        this.form_list[0].value = res.data.name
        this.form_list[1].value = res.data.banknum
      })
    },
    submit() {
      for (var i = 0; i < this.form_list.length; i++) {
        if (this.form_list[i].required == 1 && Number(this.form_list[i].value) == 0) {
          Toast(this.form_list[i].toastMsg)
          return
        }
      }

      $api.drawup({
        token: this.$store.getters['getToken'],
        bankcard_id: this.info.id,
        price: this.form_list[2].value,
        password1: this.form_list[3].value,
      }).then(() => {
        Toast("提现成功")
        setTimeout(() => {
          this.$router.back();
        }, 1000)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.withdraw {
  background: #f7f7fe;
  height: 100%;
  padding-bottom: 55px;

  .scroll {
    padding-top: 16px;

    .bank_info {
      background: #fff;
      padding: 0 16px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        font-size: 15px;

        .value {
          width: 250px;
          text-align: left;
          font-size: 14px;
        }
      }
    }

    .form {
      margin: 15px;
      background: #fff;

      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
        height: 52px;

        &:last-child {
          border: none;
        }

        .icon {
          width: 23px;
          height: 24px;
          margin-right: 15px;
        }
      }
    }
  }

  .btnBox {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 15px;
  }
}
</style>